<template>
  <Fragment>
    <VBanner
      v-if="vendor.branding.show_top_listener_banners"
      class="banner banner-top"
      dark
    >
      <div
        class="rte-content"
        v-html="vendor.branding.top_listener_banners"
      />
    </VBanner>

    <slot />

    <VBanner
      v-if="vendor.branding.show_bottom_listener_banners"
      class="banner banner-bottom"
      dark
    >
      <div
        class="rte-content"
        v-html="vendor.branding.bottom_listener_banners"
      />
    </VBanner>
  </Fragment>
</template>

<script>
import { Fragment } from 'vue-fragment'
import { mapGetters } from 'vuex'
import * as getters from '@/store/getters/types'
import '@/assets/fonts/font-awesome/index.css'

export default {
  name: 'UBanner',

  components: {
    Fragment,
  },

  computed: {
    ...mapGetters({
      vendor: getters.VENDOR,
    }),
  },
}
</script>

<style lang="scss">
.banner {
  background-size: cover;
}

.banner-bottom {
  margin-top: 2rem;
}

.v-banner .v-banner__wrapper {
  padding: 0;
}
</style>
